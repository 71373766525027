import React, { useEffect } from 'react'
import styled from 'styled-components'
import LoaderSvg from '../images/strapi/loader.inline.svg'
import { login, logout } from "../services/auth"

const defaultRedirect = '/'

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: rgb(246, 246, 249);

  svg {
    animation: spin 1s linear infinite;
    @keyframes spin {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
`

const getCookie = (key) => {
  const name = key + "="
  const decodedCookie = decodeURIComponent(document.cookie)
  const cookies = decodedCookie.split(';')
  const rawCookie = cookies.find((c) => c.trim().startsWith(name))
  return rawCookie && rawCookie.trim().substring(name.length)
}

const doLogin = (cookieKey) => {
  const authCookie = getCookie(cookieKey)
  const { token, user, rememberMe } = JSON.parse(window.atob(authCookie))
  login({ user, token, rememberMe })
  document.cookie = `${cookieKey}=; Max-Age=0; path=/; domain=${process.env.GATSBY_DOMAIN}`
}

const Auth = ({ location }) => {
  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const redirectTo = params.get('redirectTo')
    const cookieKey = params.get('c')
    const logoutParam = params.get('logout')
    const test = params.get('test')
    try {
      if (cookieKey) doLogin(cookieKey)
      if (logoutParam) logout()
    } catch(e) {
      // Ignore
      if (test) console.log(e)
    }
    if (!test) window.location.replace(redirectTo || defaultRedirect)
  }, [])

  return (
    <Wrap>
      <LoaderSvg />
    </Wrap>
  )
}

export default Auth

