const userKey = 'userInfo'
const tokenKey = 'jwtToken'

const isBrowser = typeof window !== "undefined"

const storage = rememberMe => {
  if (!isBrowser) return { getItem: () => {}, setItem: () => {}, removeItem: () => {} }
  return rememberMe ? localStorage : sessionStorage
}

const storages = isBrowser ? [localStorage, sessionStorage] : []

export const getUser = () => {
  let user
  storages.find(store => { user = store.getItem(userKey); return user })
  return user ? JSON.parse(user) : {}
}

const setUser = ({ user, rememberMe }) =>
  storage(rememberMe).setItem(userKey, JSON.stringify(user))

export const getToken = () => {
  let token
  storages.find(store => { token = store.getItem(tokenKey); return token })
  return token && token.replace(/"/g, '')
}

const setToken = ({ token, rememberMe }) =>
  storage(rememberMe).setItem(tokenKey, token)

export const login = ({ user, token, rememberMe }) => {
  setUser({ user, rememberMe })
  setToken({ token, rememberMe })
}

export const logout = () => {
  storages.forEach((store) => {
    store.removeItem(userKey)
    store.removeItem(tokenKey)
  })
}

export const isLoggedIn = () => !!getToken()
